<template>
  <div
    class="BSW-slider-input"
    :class="{ 'BSW-slider-input_with-percent': percent }"
  >
    <div class="BSW-slider-input__input-row">
      <div
        class="BSW-slider-input__input-field"
        :class="{
          'BSW-slider-input__input-field_with-description': showDescription,
        }"
      >
        <v-text-field
          class="BSW-slider-input__input-field__input"
          ref="input"
          dense
          outlined
          :label="label"
          :success="success"
          :disabled="disabled"
          :rules="rules"
          :error="errorHandler"
          hide-details
          v-model="fieldValue"
          @focus="focus"
          @blur="submitInput"
          @keydown.enter.prevent="submitInput"
        >
          <template #append>
            <v-icon
              v-if="clearable && !disabled"
              class="BSW-slider-input__clear"
              @click="clear"
              >clear
            </v-icon>
          </template>
        </v-text-field>

        <InputDescription
          class="BSW-slider-input__input-field__description"
          v-if="showDescription"
          :content="info"
          :title="label"
        />
      </div>
      <v-slider
        ref="slider"
        class="BSW-slider-input__input-slider"
        :class="{
          'BSW-slider-input__input-slider_with-description': showDescription,
        }"
        :min="min"
        :max="max"
        :hide-details="hideDetails"
        :rules="rules"
        :error="errorHandler"
        :error-messages="errorHandlerMessage"
        :success="success"
        :readonly="disabled"
        v-model="sliderValue"
        @end="submitInput"
        color="#0097CE"
      >
      </v-slider>
    </div>

    <v-text-field
      v-if="percent"
      class="BSW-slider-input__input-percent"
      dense
      outlined
      :success="success"
      :disabled="disabled"
      hide-details
      hide-spin-buttons
      type="number"
      append-icon="percent"
      v-model="percentValue"
      @focus="focus"
      @blur="submitInput"
      @keydown.enter.prevent="submitInput"
    ></v-text-field>
  </div>
</template>

<script>
import InputDescription from "@/calculator/components/CommonComponents/Inputs/InputDescription";
export default {
  name: "SliderInput",
  components: { InputDescription },
  props: {
    label: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100000000,
    },
    requiredRule: {
      type: Array,
    },
    textFieldRule: {
      type: Array,
    },
    error: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    percent: {
      type: Boolean,
    },
    maxForPercent: {
      type: Number,
    },
    info: {
      type: String,
    },
    showDescription: {
      type: Boolean,
    },
    value: {
      type: Number,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    reactiveUpdate: {
      type: Boolean,
    },
    minRule: {
      type: Boolean,
    },
    input: {
      type: Object,
    },
  },

  data() {
    return {
      inputValue: undefined,
    };
  },

  methods: {
    clear() {
      this.inputValue = this.min ?? 0;
      this.$emit("clear", this.inputValue);
    },

    focus() {
      if (this.inputValue === this.min) {
        //this.fieldValue = "";
        //this.percentValue = "";
      }
    },

    submitInput() {
      if (this.inputValue >= this.min && this.inputValue <= this.max) {
        this.$emit("input", this.inputValue);
      }
      if (this.inputValue < this.min) {
        this.$emit("input", this.min);
      }
      if (this.inputValue > this.max) {
        this.$emit("input", this.max);
      }
    },

    readableNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },

  computed: {
    errorHandler() {
      if (this.inputValue < this.min || this.inputValue > this.max) {
        return true;
      }
      return this.error;
    },
    errorHandlerMessage() {
      if (this.inputValue < this.min) {
        return `Минимально: ${this.readableNumber(this.min)}`;
      }
      if (this.inputValue > this.max) {
        return `Максимально: ${this.readableNumber(this.max)}`;
      }
      return "";
    },

    fieldValue: {
      get() {
        return this.readableNumber(this.inputValue);
      },
      set(valueProp) {
        const value = +valueProp.toString().replace(/\s/g, "");
        this.inputValue = value;
        if (value >= this.min && value <= this.max) {
          if (this.reactiveUpdate) this.$emit("reactiveInput", value);
        }
      },
    },

    sliderValue: {
      get() {
        if (this.inputValue < this.min) {
          return this.min;
        } else if (this.inputValue > this.max) {
          return this.max;
        }
        return this.inputValue;
      },
      set(value) {
        if (this.reactiveUpdate) this.$emit("reactiveInput", value);
        this.inputValue = +value;
      },
    },

    percentValue: {
      get() {
        let value = this.inputValue;
        if (this.inputValue < this.min) {
          value = this.min;
        } else if (this.inputValue > this.max) {
          value = this.max;
        }

        const max = this.maxForPercent ?? this.max;
        return Number(((value / max) * 100).toFixed(2));
      },
      set(value) {
        let val = (this.maxForPercent / 100) * value;
        this.inputValue = +val;
        if (this.reactiveUpdate) this.$emit("reactiveInput", val);
      },
    },

    rules() {
      let rule = [];
      if (this.requiredRule !== undefined) {
        rule = [...this.requiredRule];
      }
      if (this.textFieldRule) {
        rule = [...rule, ...this.textFieldRule];
      }
      return rule;
    },

    clearable() {
      return this.inputValue !== this.minInput;
    },
  },

  beforeMount() {
    if (this.value !== undefined && this.value > this.min) {
      this.inputValue = this.value;
      this.$emit("updateValue", this.value);
    } else {
      this.inputValue = this.min;
      this.$emit("updateValue", this.min);
    }
  },

  watch: {
    value() {
      this.inputValue = this.value;
    },
    maxForPercent() {
      if (this.inputValue > this.maxForPercent) {
        if (this.reactiveUpdate) {
          this.$emit("reactiveInput", this.maxForPercent);
        }
        this.inputValue = this.maxForPercent;
      }
    },
  },
};
</script>

<style lang="scss">
//.v-slider__thumb {
//  z-index: 2 !important;
//  width: 10px !important;
//  height: 10px !important;
//
//  &:after,
//  &:before {
//    width: 20px !important;
//    height: 20px !important;
//  }
//
//  &:before {
//    left: -5px !important;
//    top: -5px !important;
//  }
//}

.v-slider--horizontal {
  min-height: 5px !important;
  margin-left: 13px !important;
  margin-right: 13px !important;
}
</style>

<style scoped lang="scss">
.BSW-slider-input {
  border-radius: 10px !important;

  &_with-percent {
    display: grid;
    grid-template-columns: 4fr 100px;
    gap: 10px;
  }

  &__input-row {
    height: 40px;
  }

  &__input-field {
    &_with-description {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
    &__input {
      display: flex;
      border-radius: 10px;
    }
  }

  &__input-errorBox {
    position: relative;

    &_with-description {
      width: calc(100% - 30px);
    }
  }

  &__input-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    margin: 0;
  }

  &__clear {
    cursor: pointer;
  }

  &__bank-preview {
    position: absolute;
    z-index: 1;

    &__wrapper {
      width: 100%;
      height: 1px;
      position: relative;
      top: -13px;
    }

    &__loading {
      top: 2px;
    }

    &__circle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      top: 2px;
      height: 20px;
      width: 20px;
      background-color: #d7ebff;
      font-size: 12px;
      border-radius: 50%;
      border: 1px solid #008ece;
      text-align: center;
    }
  }
}
</style>
