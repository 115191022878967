<template>
  <v-tooltip top open-delay="500" :disabled="hideTooltip">
    <template #activator="{ on, attrs }">
      <v-autocomplete
        v-bind="attrs"
        :id="label"
        rounded
        v-on="on"
        class="BSW-select-input"
        :class="{
          onlyValue: !outlined,
        }"
        dense
        :outlined="outlined"
        hide-no-data
        :aria-autocomplete="offAutocomplete ? 'off' : 'on'"
        :hide-details="hideDetails"
        :value="value"
        :label="label"
        :height="multiple && inputValue !== 0 ? 'auto' : height"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :rules="rules || enableRules"
        :error="error || !!errorMessage"
        :error-messages="errorMessage"
        :success="success"
        :color="color"
        :background-color="bgColor"
        :multiple="multiple"
        :minHeigth="minHeight"
        :disabled="disabled"
        @input="updateValue($event)"
        @click.native="$emit('click.native')"
        @blur="blurUpdateValue()"
        :chips="multiple"
        color="#0097CE"
        :prepend-icon="prependIcon"
      >
        <template #append v-if="!notClearable && value !== undefined">
          <v-icon
            class="BSW-select-input__clear"
            @click="$emit('clear')"
            :disabled="disabled"
            >clear</v-icon
          >
        </template>
        <template #item="{ item }" v-if="itemValue">
          <div class="BSW-select-input__item">
            <v-divider
              v-if="item[itemValue] === 2514 || item[itemValue] === 2834"
              class="BSW-select-input__item-divider"
              :class="{ bottom: item[itemValue] === 2834 }"
            />
            <div class="BSW-select-input__item-content">
              <span>
                {{ item[itemText] }}
                <v-icon v-if="item[itemIcon]">{{ item[itemIcon] }}</v-icon>
              </span>

              <span
                class="BSW-select-input__item-check"
                :class="{
                  active:
                    item[itemValue] === value ||
                    (multiple && value?.includes(item[itemValue])),
                }"
              >
                <v-icon
                  v-if="
                    item[itemValue] === value ||
                    (multiple && value?.includes(item[itemValue]))
                  "
                  dense
                  >mdi-check-bold</v-icon
                >
              </span>
            </div>
          </div>
        </template>
        <template #selection="{ item }" v-if="multiple || withBg">
          <v-chip class="BSW-select-input__chip" small>
            <span class="BSW-select-input__chip__text" :class="{ wrapText }">{{
              item.name
            }}</span>
          </v-chip>
        </template>
        <template #append-outer>
          <InputDescription
            class="BSW-select-input__description"
            v-if="showDescription"
            :content="info"
            :title="label"
          />
        </template>
      </v-autocomplete>
    </template>
    <div>
      <span>{{ label }}</span>
      <ul class="BSW-select-input__tooltip-list">
        <li v-for="(item, i) of items" :key="i">{{ item.name }}</li>
      </ul>
    </div>
  </v-tooltip>
</template>

<script>
import InputDescription from "@/calculator/components/CommonComponents/Inputs/InputDescription";

export default {
  name: "SelectInput",
  components: { InputDescription },
  props: {
    /** Значение поля, обязательные параметр */
    value: {
      required: true,
    },
    /** label поля */
    label: {
      type: String,
    },
    /** Высота поля */
    height: {
      type: String,
      default: "40",
    },
    minHeight: {
      type: String,
      default: "40",
    },
    /** Массив значений поля, сами значения должны быть объектами */
    items: {
      type: Array,
    },
    /** Отображаемое значение поля, сюда передается ключ объекта с названием поля */
    itemText: {
      type: String,
    },
    /** Отображаемое значение поля, сюда передается ключ объекта с названием поля */
    itemIcon: {
      type: String,
    },
    /** Значение поля, сюда передается ключ объекта со значением поля */
    itemValue: {
      type: String,
    },
    /** Задает цвет фона поля*/
    bgColor: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    color: {
      type: String,
    },
    notClearable: {
      type: Boolean,
    },
    rules: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    withBg: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    info: {
      type: String,
    },
    showDescription: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    hideTooltip: {
      type: Boolean,
    },
    offAutocomplete: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    wrapText: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    prependIcon: {
      type: String,
    },
  },

  data() {
    return {
      inputValue: 0,
      validateRules: {
        required: (v) => v !== "" || "Обязательное поле",
      },
    };
  },
  computed: {
    /**
     * Применение правил валидации к полю
     * @returns {*[]} возвращает массив функций
     */
    enableRules() {
      let rules = [];
      Object.keys(this.validateRules).forEach((rule) => {
        if (this.rules !== undefined && this.rules.includes(rule)) {
          rules.push(this.validateRules[rule]);
        }
      });
      return rules;
    },
  },

  methods: {
    /** Обновляет значение поля
     * @param value актуальное значение
     */
    updateValue(value) {
      this.inputValue = value;
      this.$emit("input", value);
    },

    blurUpdateValue() {
      this.$emit("blur", this.inputValue);
    },
  },

  watch: {
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
<style lang="css">
.v-list-item__title {
  font-size: 16px !important;
  font-weight: 400 !important;
  z-index: 1;
}
.v-application .v-autocomplete__content.menuable__content__active {
  border-radius: 13px !important;
  z-index: 9999999 !important;
}
.v-list-item--link:before {
  background-color: transparent;
  opacity: 1;
  margin: 0 7px;
}
.v-list-item:hover::before,
.v-list-item--active::before {
  background-color: #f5f5f5;
  opacity: 1 !important;
  border-radius: 10px;
}

.v-list--dense .v-list-item {
  min-height: auto;
  padding: 7px 16px;
}
</style>

<style scoped lang="scss">
@import "@/styles/mixins";
.BSW-select-input {
  border-radius: $s-border-radius !important;
  margin-top: 0 !important;

  :deep(.v-icon) {
    font-size: 30px;
    color: #989898;
  }

  &.onlyValue {
    :deep(.v-select__slot) {
      padding: 0;
      & > label {
        display: none;
      }
      & > .v-input__append-inner {
        display: none;
      }
      & > input {
        cursor: pointer;

        &:hover {
          color: var(--main-color);
        }
      }
    }
    :deep(.v-input__slot) {
      padding: 0;
    }
  }

  :deep(.v-input__slot) {
    padding: 0 12px 0 24px;

    &:after {
      display: none;
    }
  }
  &__clear {
    cursor: pointer;
    font-size: 24px !important;
    color: #989898 !important;
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    color: #000000;
    padding: 0 11px;
    &-divider {
      width: 100%;
      position: absolute;
      top: -10px;

      &.bottom {
        top: calc(100% + 6px);
      }
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-check {
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 1px solid #d9d9d9;
      color: #ffffff;
      @include center();
      margin-left: 5px;

      &.active {
        background-color: var(--main-color);
        border: none;
      }
    }
  }
  &__tooltip-list {
    @media (min-width: 600px) {
      display: none;
    }
  }

  &__chip {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    height: 100%;

    @media (max-width: 600px) {
      max-width: 180px !important;
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.wrapText {
        white-space: pre-wrap;
      }
    }
  }

  &__description {
    margin-top: -3px;
  }
}
</style>
