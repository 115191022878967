export const testExcludedParams = (parameters, excludedParams) => {
    if (excludedParams) {
        const preparedExcludedParams = Object.entries(excludedParams).filter((el) => el[1].length);
        if (preparedExcludedParams && preparedExcludedParams.length) {
            const excluded = preparedExcludedParams.every((param) => {
                return (parameters[param[0]] && param[1].includes(parameters[param[0]].value));
            });
            if (excluded) {
                return false;
            }
        }
        else {
            return true;
        }
    }
    return true;
};
export const testDopParams = (parameters, dopParams) => {
    if (dopParams) {
        const preparedDopParams = Object.entries(dopParams).filter((el) => el[1].length > 0);
        if (preparedDopParams.length === 0) {
            return true;
        }
        const hasAtLeastOneParam = preparedDopParams.some(([key]) => parameters.hasOwnProperty(key));
        const isAllValid = preparedDopParams.every((param) => {
            return !(parameters[param[0]] && !param[1].includes(parameters[param[0]].value));
        });
        return hasAtLeastOneParam && isAllValid;
    }
    else {
        return true;
    }
};
