<template>
  <v-btn
    :color="color"
    class="elevation-0"
    :class="[
      light ? 'main-btn-light' : 'main-btn',
      small ? 'small' : '',
      white ? 'main-btn-white' : 'main-btn',
      black ? 'main-btn-black' : 'main-btn',
      orange ? 'main-btn-orange' : 'main-btn',
      { 'rounded-xl': !customStyles },
      { upperCase: upperCase },
    ]"
    :height="height"
    :width="width"
    :max-width="maxWidth"
    :loading="loading"
    :disabled="disabled"
    :href="link"
    :target="link ? '_blank' : ''"
    @mouseenter="toggleIcon('hov')"
    @mouseleave="toggleIcon('def')"
    @click="$emit('click')"
    @mouseup="$emit('mouseup', $event)"
  >
    <img
      class="main-btn__img mr-4"
      :src="icon.def"
      ref="icon"
      v-if="icon !== undefined"
      alt="icon"
    />
    <v-icon v-if="mdIcon !== undefined" class="pr-2">{{ mdIcon }}</v-icon>
    {{ text }}

    <v-icon v-if="afterMdIcon !== undefined">{{ afterMdIcon }}</v-icon>
  </v-btn>
</template>

<script>
/**
 * Компонент кнопки
 */
export default {
  name: "MainBtn",
  props: {
    /**
     * Текст кнопки
     */
    text: {
      type: String,
      default: "кнопка",
    },
    /**
     * Высота кнопки
     */
    height: {
      type: String,
      default: "48",
    },
    /**
     * Ширина кнопки
     */
    width: {
      type: String,
    },
    /** Максимальная ширина */
    maxWidth: {
      type: String,
    },
    /**
     * Спиннер загрузки
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Объект с двумя вариантами картинки, в "спокойном" виде и при наведении
     */
    icon: Object,
    /** Иконка google fonts  */
    mdIcon: {
      type: String,
    },
    afterMdIcon: {
      type: String,
    },
    /**
     * Тема кнопки
     */
    light: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    black: {
      type: Boolean,
      default: false,
    },
    orange: {
      type: Boolean,
      default: false,
    },
    /**
     * Цвет кнопки
     */
    color: {
      type: String,
      default: "main-color-bg",
    },
    /**
     * Флаг блокировки кнопки
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    customStyles: {
      type: Boolean,
      default: false,
    },
    upperCase: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    /**
     * функция смены иконки на кнопке
     * @param {string} type тип события: hover или default
     */
    toggleIcon(type) {
      if (this.icon !== undefined) {
        this.$refs.icon.src = this.icon[type];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../../styles/variables";
.main-btn {
  transition: 0.3s;
  border: 1px solid transparent !important;
  padding: 0 20px !important;
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  letter-spacing: normal;

  &.upperCase {
    text-transform: uppercase;
    font-weight: 700;
  }

  &:hover {
    background-color: var(--main-color-hover) !important;
    transition: 0.3s;
  }

  &-light {
    background-color: transparent !important;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    transition: 0.3s;
    font-size: 16px;
    white-space: normal !important;

    &:hover {
      background-color: transparent !important;
      color: #2d2d2d !important;
      border: 1px solid #2d2d2d !important;
      transition: 0.3s;
    }
  }

  &-black {
    background-color: transparent !important;
    color: #000000 !important;
    border: 1px solid #000000 !important;
    transition: 0.3s;
    font-size: 14px;

    &:hover {
      background-color: transparent !important;
      color: var(--main-color) !important;
      border: 1px solid var(--main-color) !important;
      transition: 0.3s;
    }
  }

  &-white {
    background-color: white !important;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    transition: 0.3s;
    font-size: 14px;

    &:hover {
      background-color: var(--main-color) !important;
      border: 1px solid var(--main-color) !important;
      color: white !important;
      transition: 0.3s;
    }
  }

  &-orange {
    color: #fff !important;
    background-color: #ff7c10 !important;

    &:hover {
      color: #fff !important;
      background-color: #ec7412 !important;
      border: none;
      transition: 0.3s;
    }
  }
}
.small {
  font-size: 12px;
}
</style>
